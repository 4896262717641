<template>
  <div ref="student" class="student">
    <keep-alive>
      <list-template @onExportData="onExportData" :current-page="page" :loading="loading" :table-config="tableConfig" :table-data="tableData" :total="total" @onChangePage="changeCurrentPage" @onHandle="handleEdit">
        <template v-slot:title>
          <el-form ref="form" :model="form" label-width="0" style="padding-right: 40rem">
            <div class="flex flex-align">
              <el-form-item>
                <el-select v-model="form.status" placeholder="筛选是否办结" size="small" @enter="onSearch">
                  <el-option label="未完结" value="0"></el-option>
                  <el-option label="已完结" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.school_id" placeholder="筛选报名校区" size="small" @enter="onSearch">
                  <el-option v-for="item in schoolArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.grade" placeholder="筛选就读年级" size="small" @enter="onSearch">
                  <el-option v-for="item in gradeArr" :key="item.value" :label="item.label" :value="item.label"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.student_name" placeholder="搜索学生姓名" size="small" @enter="onSearch"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.id_card" placeholder="搜索学生身份证" size="small" @enter="onSearch"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.parent_name" placeholder="搜索家长姓名" size="small" @enter="onSearch"></el-input>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <el-button debounce size="small" style="margin-left: 16rem" @click.prevent="reset">重置</el-button>
            </div>
          </el-form>
        </template>
      </list-template>
    </keep-alive>
    <el-dialog :visible.sync="centerDialogVisible" center title="办理详情" width="30%">
      <div style="display: flex;justify-content: center">
        <el-form label-position="left" label-width="160rem">
          <el-form-item label="办理状态">{{ payment.status === 0 ? '未完结' : '已完结' }}</el-form-item>
          <el-form-item label="学生姓名">{{ payment.student_name }}</el-form-item>
          <el-form-item label="家长姓名">{{ payment.parent_name }}</el-form-item>
          <el-form-item label="原学校">{{ payment.old_school_name }}</el-form-item>
          <el-form-item label="入学年级">{{ payment.grade }}</el-form-item>
          <el-form-item label="现学校">{{ payment.new_school_name }}</el-form-item>
          <el-form-item label="学生是否入学">{{ payment.is_start }}</el-form-item>
          <el-form-item label="招生老师姓名">{{ payment.recruit_teacher_name }}</el-form-item>
          <el-form-item label="创建时间">{{ payment.created_at }}</el-form-item>
          <el-form-item label="办理人">{{ payment.complete_name }}</el-form-item>
          <el-form-item label="办理完成时间">{{ payment.complete_time }}</el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisibleTwo" center width="30%">
      <div>
        <div style="text-align: center">
          <span>【{{ payment.parent_name }}】的由【{{ payment.old_school_name }}】转学到【{{ payment.new_school_name }}】的转学业务是否在线下已经结款完毕？</span>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onToHandle">已办理</el-button>
          <el-button @click="centerDialogVisibleTwo = false">点错了</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import {mapState} from 'vuex'
import {utils} from "@/utils/utils";
import tools from "@/utils/tools";

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      form: {
        status: '',
        keyword: '',
      },
      payment: {},
      // 表格配置
      tableConfig: [
        {prop: 'status_name', label: '是否办结'},
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'idcard', label: '学生身份证'},
        {prop: 'parent_name', label: '家长姓名'},
        {prop: 'school_name', label: '报名学校'},
        {prop: 'new_school_name', label: '实际就读学校'},
        {prop: 'grade', label: '就读年级'},
        {prop: 'student_status_name', label: '学生状态'},
        {
          label: '操作',
          width: 90,
          handle: true,
          showOverflowTooltip: false,
          render: (row) => {
            return row.status ? ['查看'] : ['办理'];
          }
        }
      ],
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: [],
      centerDialogVisible: false,
      centerDialogVisibleTwo: false,
      schoolArr: [],
      gradeArr: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.$_register.get('api/recruit/common/consulting-school').then(res => {
      this.schoolArr = JSON.parse(JSON.stringify(res.data.data.entity_school).replace(/id/g, 'value').replace(/school_name/g, 'label'));
    })
    this.$_register.get('api/recruit/common/grade').then(res => {
      console.log(res.data.data)
      this.gradeArr = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'));
    })
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let {form, page} = this
      this.loading = true;
      this.$_register.get('api/recruit-v2/transfer-list', {params: {...form, page}}).then(res => {
        let data = res.data.data
        if (Array.isArray(data)) {
          this.tableData = []
          this.total = 0
        } else {
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(() => this.loading = false)
    },
    // 搜索功能
    onSearch() {
      this.$store.commit('setPage', 1)
      this.getData()
    },

    //重置搜索框
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
      this.$store.commit('setPage', 1)
      this.getData()
    },

    handleEdit(row, text) {
      if (text.type === 'view') {
        this.centerDialogVisible = true
      } else {
        this.centerDialogVisibleTwo = true
      }
      this.payment = row
    },

    onExportData() {
      let {form, page} = this
      this.$_register.get('api/recruit-v2/transfer-list', {params: {...form, page, export: 1}}).then(res => {
        this.$tools.download("集团间转校记录", res.data);
      })
    },
    // 已办理
    onToHandle() {
      this.$_register.post('api/recruit-v2/update-transfer-status', {id: this.payment.id}).then(() => {
        this.getData()
        this.centerDialogVisibleTwo = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: auto !important;
}

::v-deep .el-form-item {
  margin-right: 20rem !important;
  margin-bottom: 12rem !important;
}

::v-deep .el-button--small {
  padding: 9rem 15rem;
  font-size: 12rem;
  border-radius: 3rem;
}

::v-deep .el-button--small {
  height: 32rem;
  margin-bottom: 12rem;
}

.dialog-footer {
  width: 100%;
  text-align: center;
  margin-top: 48rem;
}
</style>
